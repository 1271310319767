<template>

	<div>
		<div class="block-header">
			<div class="row">
				<div class="col-lg-7 col-md-6 col-sm-12">
					<h2>部门管理
						<small class="text-muted"></small>
					</h2>
				</div>
			</div>
		</div>

		<div class="container-fluid">
			<div class="row clearfix">
				<div class="col-lg-12">
					<div class="card">
						<div class="body" @keyup.enter="queryList(1)">
							<el-row>
								<el-col :span="12">
									<el-input placeholder="请输入内容" v-model="query_keyword" size="mini"
										class="input-with-select">
										<el-button slot="append" icon="el-icon-search" @click="queryList()"></el-button>
									</el-input>
								</el-col>

								<el-col :span="3" :offset="5">
									<el-button-group>
										<el-button type="primary" size="mini" icon="el-icon-plus" @click="add">
										</el-button>
										<el-button type="danger" size="mini" icon="el-icon-delete" @click="del">
										</el-button>
									</el-button-group>
								</el-col>

							</el-row>
							<br /><br />

							<el-table ref="multipleTable" :data="list" style="width: 100%" border
								@selection-change="handleSelectionChange" height="calc(100vh - 270px)" size="mini">
								<el-table-column type="selection"></el-table-column>
								<el-table-column label="序号" width="60px" align="center">
									<template v-slot="scope">
										{{scope.$index+1}}
									</template>
								</el-table-column>
								<el-table-column prop="name" label="部门名称" align="center"></el-table-column>
								<el-table-column label="操作" align="center">
									<template v-slot="scope">
										<el-button icon="el-icon-edit"  size="small" @click="editor(scope.row)">编辑</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import deptController from "../controller/deptController.js"
	export default {

		data() {
			return {
				multipleSelection: [],
				list: [],
				query_keyword: '',
			}
		},
		created() {
			this.queryList()
		},
		methods: {
			queryList() {
				deptController.list({
					keyword: this.query_keyword,
				}).then(res => {
					this.list = res.data;
				})

			},
			add() {

				this.$prompt('请输入部门名称', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
				}).then(({
					value
				}) => {

					if (value) {
						deptController.post({
							name: value
						}).then(res => {
							this.queryList();
						})
					}
				}).catch(() => {});

			},
			editor(dept){
				dept = this.deepClone(dept)
				
				this.$prompt('修改部门名称', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputValue:dept.name
				}).then(({
					value
				}) => {
					if (value) {
						deptController.put({
							id:dept.id,
							name: value
						}).then(res => {
							this.queryList();
						})
					}
				}).catch(() => {});
				
			},
			del() {
				if (this.multipleSelection.length > 0) {

					this.$confirm('此操作将永久删除勾选数据, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						let array = [];
						for (let i = 0; i < this.multipleSelection.length; i++)
							array.push(this.multipleSelection[i].id)
						deptController.del({
							ids: array.toString()
						}).then(res => this.queryList())
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消删除'
						})
					});

				} else {
					this.$message.error('请至少勾选一条数据')
				}
			},
			toggleSelection(rows) {
				if (rows) {
					rows.forEach(row => {
						this.$refs.multipleTable.toggleRowSelection(row);
					});
				} else {
					this.$refs.multipleTable.clearSelection();
				}
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			}
		}

	}
</script>

<style scoped>

	.input-with-select .el-input-group__prepend {
		background-color: #fff;
	}
</style>
